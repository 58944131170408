import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER, kickout_rfi_monthly } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function KickoutRFI()
{
    const navigate = useNavigate();

   const [proposalSigned, setProposalSigned]= useState([]);
   const [proposalSignedValue, setProposalSignedValue]= useState([]);
   const [createdMonth, setCreatedMonth]= useState([]);
   const [createdYear, setCreatedYear] = useState([]);
   const [regionWiseData, setRegionWiseData] = useState([]);

   useEffect( ()=>{
       const proposalsigned=[];
       const proposalsignedvalue=[];
       const createdmonth=[];
       const YearCreated = [];

       const getProposalScopeWise= async()=>{
       const payload = { "fy": "" };
       const reqData= await axios.post(`${kickout_rfi_monthly}`, payload,API_HEADER);
       const MyprogramwiseData = reqData.data.kickout_monthly

       for(let i=0; i< MyprogramwiseData.length; i++)
       {        

        proposalsigned.push(((MyprogramwiseData[i].total_rfi_count)));
        const dateString = MyprogramwiseData[i].month_year_created;
        const [month, year] = dateString.split('-');
        const date = new Date(year, parseInt(month) - 1);
        const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
        const formattedYear = date.getFullYear();
        createdmonth.push(formattedMonth);
        YearCreated.push(formattedYear);
       }
       
       setProposalSigned(proposalsigned);
       setProposalSignedValue(proposalsignedvalue);
       setCreatedMonth(createdmonth);
       setCreatedYear(YearCreated)
       setRegionWiseData(MyprogramwiseData);

       }

       getProposalScopeWise();

   },[]);

   const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
    const contract_signed_status = w.globals.seriesNames[seriesIndex];

    const title = w.globals.seriesNames[dataPointIndex];

    const contract_signed_month = w.globals.labels[dataPointIndex];

    if (contract_signed_month) {
       navigate('/programwiseprojecttable', { state: { title:"RFI Completed",contract_signed_status:"Project Delivered", contract_signed_month } });
    }

   };

   return(
    <React.Fragment>
        <div className="container-fluid">
           
            <Chart
            type="bar"
            width={"100%"}
            height={400}
            series={[
                {
                    name:"RFI Completed",
                    data: proposalSigned,
                    color: '#182b55'
                },
            ]}

            options={{
                chart:{
                    toolbar: {
                        show: false,  
                      },
                    stacked:false,
                    events: {
                        dataPointSelection: handleChartClick,
                    },
                    margin: {
                        top: 20, // Add margin to the top of the chart
                    },
                },
                plotOptions:{
                    bar: {
                        horizontal: false,
                        columnWidth: '40%',
                        dataLabels: {
                          position: "top", // Display labels on top of the bars
                          offsetY: -10, // Slightly offset the labels above the bars
                        }
                    }
                    
                },
                stroke: {
                    width: 1,
                },
                xaxis:{
                    title:{
                        text: `Month`,
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold'
                        }
                    },
                    categories : createdMonth
                },
                yaxis:{
                    title:{
                        text:"Number of Projects",
                        style:{fontSize:'16px',fontWeight:'bold'}
                    },
                   
                },
                legend:{
                    position: 'bottom',
                },
                dataLabels:{
                    enabled: true,
                    position: "top", // Show the label on top of the bars
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      colors: ["#304758"],
                    },
                    offsetY: -20, // Add padding to ensure it's outside the bar
                    formatter: function (val) {
                        return val === 0 ? "" : val.toFixed(0); // Show empty string for 0
                    },
                },
                grid: {
                    show:true,
                    xaxis:{
                        lines:{
                            show:false
                        }
                    },
                    yaxis:{
                        lines:{
                            show:false
                        }
                    }

                },
                grid: {
                    borderColor: '#f1f1f1',
                    row: {
                      colors: ['transparent'], 
                      opacity: 1,
      
                    },
                  },

            }}

            />
        </div>
    </React.Fragment>
);
}
export default KickoutRFI;


// import React from "react";

// function ProjectStageWise({ startDate, endDate }) {
//   const grafanaBaseUrl = "http://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
//   const orgId = 1;
//   const timezone = "browser";
//   const panelId = 19;
//   const theme = "light";

//   // Convert start and end date to UNIX timestamps (milliseconds)
//   const from = startDate ? new Date(startDate).getTime() : Date.now() - 86400000; // Default: 24 hours ago
//   const to = endDate ? new Date(endDate).getTime() : Date.now(); // Default: now

//   // Construct Grafana iframe URL dynamically
//   const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

//   return (
//     <div className="container-fluid">
//       <iframe
//         src={grafanaUrl}
//         width="100%"
//         height="400"
//         frameBorder="0"
//         title="Program Wise Projects"
//         style={{ border: "none" }}
//       ></iframe>
//     </div>
//   );
// }

// export default ProjectStageWise;