
import React, { useState } from "react";
import Logo from "../assets/logo.png";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { feedback_url } from "../config";

const FeedbackForm = () => {
  const { token } = useParams();
   const navigate = useNavigate();
  const [formData, setFormData] = useState({
    reasons: '', // For checkboxes
    priceComparison: "", // For radio buttons
    technicalExpertise: "", // For technical expertise rating
    otherReason: "", // For other reason text input
    additionalComments: "", // For additional comments
  });

  // const handleCheckboxChange = (event) => {
  //   const { id, checked } = event.target;
  //   setFormData((prevData) => {
  //     const reasonsArray = checked
  //       ? [...prevData.reasons.split(',').filter(Boolean), id]
  //       : prevData.reasons.split(',').filter(Boolean).filter((reason) => reason !== id);
  //     return { ...prevData, reasons: reasonsArray.join(',') };
  //   });
  // };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
  
    setFormData((prevData) => {
      if (id === "otherReason") {
        return {
          ...prevData,
          otherReason: checked ? prevData.otherReason : "",
          reasons: checked
            ? [...prevData.reasons.split(',').filter(Boolean), id].join(',')
            : prevData.reasons.split(',').filter((reason) => reason !== id).join(','),
        };
      }
  
      const reasonsArray = checked
        ? [...prevData.reasons.split(',').filter(Boolean), id]
        : prevData.reasons.split(',').filter((reason) => reason !== id);
  
      return { ...prevData, reasons: reasonsArray.join(',') };
    });
  };
  

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleClearForm = () => {
    setFormData({
      reasons: '',
      priceComparison: "",
      technicalExpertise: "",
      otherReason: "",
      additionalComments: "",
    });
  
    // Clear checkboxes and radio buttons manually
    document.querySelectorAll("input[type='checkbox'], input[type='radio']").forEach((input) => {
      input.checked = false;
    });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const payload = {
        token,
        main_reason: formData.reasons,
        price: formData.priceComparison,
        technical_expertise: formData.technicalExpertise,
        otherReason: formData.otherReason,
        suggestion: formData.additionalComments,
      };

      const response = await axios.post(feedback_url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response:", response.data.status);
      if(response.data.status){
        navigate('/feedbackthanks')
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit feedback. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="d-flex flex-column align-items-center p-3"
        style={{ backgroundColor: "#e0f7fa" }}
      >
        {/* Logo Section */}
        <div className="border d-flex justify-content-center align-items-center border-2 rounded w-50">
          <img src={Logo} alt="Logo" className="w-100 h-10" />
        </div>

        {/* Title and User Info */}
        <div className=" mt-2 p-2 w-50">
          <h3 className=" ml-0">Proposal Feedback</h3>
        </div>

        {/* Checkbox Group */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>
            What were the main reasons you decided not to accept our proposal?
            Select all that apply:
          </h5>
          {[
            "Price was too high",
            "Lack of technical expertise",
            "Found a more suitable vendor",
            "Unclear proposal details",
            "Project timelines were not suitable",
          ].map((reason, index) => (
            <div className="form-check my-1" key={index}>
              <input
                className="form-check-input my-1"
                type="checkbox"
                id={`reason${index + 1}`}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor={`reason${index + 1}`}>
                {reason}
              </label>
            </div>
          ))}
          <div className="form-check">
            <input
              className="form-check-input my-1"
              type="checkbox"
              id="otherReason"
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="otherReason">
              Other:
            </label>
            {/* <input
              type="text"
              id="otherReason"
              className="form-control mt-2"
              placeholder="Please specify"
              onChange={handleInputChange}
            /> */}

<input
  type="text"
  id="otherReason"
  className="form-control mt-2"
  placeholder="Please specify"
  value={formData.otherReason} // Bind the value
  onChange={handleInputChange}
/>
          </div>
        </div>

        {/* Radio Group */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>
            How did our proposed price compare to other VVBs you considered?:
          </h5>
          {[
            "Much higher (+50%)",
            "Slightly higher (+20%)",
            "About the same",
            "Slightly lower",
            "Much lower",
          ].map((option, index) => (
            <div className="form-check my-1" key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="priceComparison"
                value={option}
                onChange={handleRadioChange}
              />
              <label className="form-check-label">{option}</label>
            </div>
          ))}
        </div>

        {/* Technical Expertise */}
        <div className="card  p-4 w-50 shadow-sm">
          <h5>
            How would you rate the technical expertise demonstrated in our
            proposal?
          </h5>
          <div className="d-flex justify-content-between align-items-center my-4">
            <div className="my-4">Very Low</div>
            {[1, 2, 3, 4, 5].map((rating) => (
              <div className="" key={rating}>
                <div className="">{rating}</div>
                <div>
                  <input
                    type="radio"
                    name="technicalExpertise"
                    value={rating}
                    onChange={handleRadioChange}
                  />
                </div>
              </div>
            ))}
            <div className="my-4">Very High</div>
          </div>
        </div>

        {/* Additional Comments */}
        <div className="card p-4 w-50 shadow-sm">
          <h5>
            Please provide any additional comments or suggestions that could
            help us improve our proposals in the future.
          </h5>
          {/* <input
            type="text"
            id="additionalComments"
            className="form-control"
            placeholder="Your Answer"
            onChange={handleInputChange}
          /> */}
          <input
  type="text"
  id="additionalComments"
  className="form-control"
  placeholder="Your Answer"
  value={formData.additionalComments} // Bind the value here
  onChange={handleInputChange}
/>

        </div>

        <div className="d-flex mt-2 gap-3">
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
          
          <button
            className="btn btn-secondary"
            type="button"
            onClick={handleClearForm}
          >
            Clear form
          </button>
        </div>
      </div>
    </form>
  );
};

export default FeedbackForm;
