// import { Form as NewForm } from "antd";
// import axios from "axios";
// import "../App.css";
// import { Tooltip } from 'antd';
// import React, { useState, useEffect,} from "react";
// import { useNavigate,useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { get_client_name_url, get_scope_pa_poa_url, get_meths_url,  } from "../config";
// import { get_scope_url } from "../config";
// import { get_program_url } from "../config";
// import { get_country_url } from "../config";
// import { get_trsbu_url, } from "../config";
// import SideNavbar from "../Components/SideNavbar";
// import Footer from "../Components/Footer";
// import Select from "react-select";
// import { DatePicker, } from 'antd';
// import moment from 'moment';
// import dayjs from 'dayjs';
// import Header from "../Pages/Header";
// import { API_HEADER,get_project_details_url,update_project_details_url } from "../config";

// const { RangePicker } = DatePicker;

// const TLProposalChange = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();

//   const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
//   const dynamicMaxSize = my_file_maxSize.max_document_size

//   const [projectid, setProjectId] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [myscope, setMyScope] = useState([]);
//   const [program, setProgram] = useState([]);
//   const [trlist, setTRList] = useState([]);
//   const [myprojectName, setMyProjectName] = useState("");
//   const [error, setError] = useState("");
//   const [programId, setProgramId] = useState("");
//   const [programIdError, setProgramIdError] = useState("");
//   const [monitoringPeriod, setMonitoringPeriod] = useState([]);

//   const [weblink, setWeblink] = useState("");

//   const [proposalDateError, setProposalDateError] = useState("");

//   const [methOptions, setMethOptions] = useState([]);
//   const [selectedMeth, setSelectedMeth] = useState(null);
//   const [defaultMethName, setDefaultMethName] = useState(null);
//   const [methStatus, setMethStatus] = useState(null);
//   const [clientOptions, setClientOptions] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [defaultClientName, setDefaultClientName] = useState(null);
//   const [clientStatus, setClientStatus] = useState(null);
//   const [defaultCountryName, setDefaultCountryName] = useState(null);
//   const [scope_PA_POA, setScope_PA_POA] = useState([]);
//   const [percent, setPercent] = useState(0);

//   const [formData, setFormData] = useState({
//     project_name: "",
//     // country: "",
//     program_id: "",
//     scope_pa: "",
//     meth: "",
//     sbu_head: "",
//     deadline_date: '',
//     additional_emails:""
//   });

//   const [rangeForm] = NewForm.useForm();

//   useEffect(() => {
//     const fetchDetails = async (id) => {

//       try {
//         const response = await axios.get(
//           `${get_project_details_url}/${id}`,
//           API_HEADER
//         );

//         if (response && response.data && response.data.record) {
//           const { record } = response.data;
//           setProjectId(record.id);
//           setFormData({
//             project_name: record.project_name,
//             // country: record.country,
//             program_id: record.program_id,
//             scope_pa: record.scope_pa,
//             meth: record.meth,
//             sbu_head: record.sbu_head,
//             deadline_date: record.deadline_date,
//             additional_emails:record.additional_emails
//           })
//           setMonitoringPeriod(record.monitoring_period);
//           setWeblink(record.weblink);
//           setSelectedMeth(record.meth);
//           setMethStatus(record.meth)
//           setProjectId(record.id);

//           if (record?.monitoring_period) {
//             setMonitoringPeriod(JSON.parse(record.monitoring_period))
//           }
//         }

//       } catch (error) {
//       }
//     };
//     fetchDetails(id);
//   }, [id]);

//       const CONFIG_Token = {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + sessionStorage.getItem("token"),
//         },
//       };

//       const handleMyProjectChange = (event) => {
//         const { value } = event.target;
//         setMyProjectName(value);

//         if (value.length < 3 || value.length > 500) {
//           setError(
//             "Project Name must be in alphabatic characters with 3 to 500 characters"
//           );
//         } else {
//           setError("");
//         }
//         setFormData({ ...formData, project_name: event.target.value });
//       };

//       const handleProgramIdChange = (event) => {
//         const value = event.target.value;
//         setProgramId(value);

//         if (!/^[a-zA-Z0-9]{3,40}$/.test(value)) {
//           setProgramIdError(
//             "Earthood Id must be alphanumeric and between 3 to 40 characters"
//           );
//         } else {
//           setProgramIdError("");
//         }
//         setFormData({ ...formData, program_id: event.target.value });
//       };

//       useEffect(() => {
//         const fetchClientOptions = async () => {
//           try {
//             const response = await axios.get(`${get_client_name_url}`);
//             const data = response.data;
//             setClientOptions(
//               data.data.map((item) => ({ value: item.id, label: item.name }))
//             );
//             const defaultClient = data.data
//               .filter((item) => item.id === selectedClient)
//               .map((client) => ({ value: client.id, label: client.name }));
//             setDefaultClientName(defaultClient[0]);
//           } catch (error) {
//           }
//         };

//         fetchClientOptions();
//       }, [selectedClient]);

//       const handleMethChange = (selectedOption) => {
//         setSelectedMeth(selectedOption);
//         setMethStatus(selectedOption.value);
//       };

//       useEffect(() => {
//         const fetchDataTrData = async () => {
//           try {
//             const responsetrdata = await axios.get(`${get_trsbu_url}`);
//             setTRList(responsetrdata.data.data);
//           } catch (error) { }
//         };

//         fetchDataTrData();
//       }, []);

//       useEffect(() => {
//         const fetchMethOptions = async () => {
//           try {
//             const response = await axios.get(`${get_meths_url}`);
//             const data = response.data;
//             setMethOptions(
//               data.data.map((item) => ({ value: item.id, label: item.name }))
//             );
//             const defaultMeth = data.data
//               .filter((item) => item.id == selectedMeth)
//               .map((meth) => ({ value: meth.id, label: meth.name }));
//             setDefaultMethName(defaultMeth[0]);
//           } catch (error) {
//           }
//         };

//         fetchMethOptions();
//       }, [selectedMeth]);

//       useEffect(() => {
//         const fetchDataScope = async () => {
//           try {
//             const responsescope = await axios.get(`${get_scope_url}`);

//             setMyScope(
//               responsescope.data.data.map((scope) => ({
//                 value: scope.id,
//                 label: scope.sector_name,
//               }))
//             );
//           } catch (error) { }
//         };
//         fetchDataScope();
//       }, []);

//       useEffect(() => {
//         const fetchDataProgram = async () => {
//           try {
//             const responseprogram = await axios.get(`${get_program_url}`);
//             setProgram(responseprogram.data.data);
//           } catch (error) { }
//         };

//         fetchDataProgram();
//       }, []);

//       useEffect(() => {
//         const fetchDataSope_PA_POA = async () => {
//           try {
//             const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

//             setScope_PA_POA(responsescope.data.data);
//           } catch (error) { }
//         };

//         fetchDataSope_PA_POA();
//       }, []);

//       useEffect(() => {
//         if (monitoringPeriod && monitoringPeriod.length > 0) {
//           rangeForm.setFieldValue("startEndDate", [dayjs(monitoringPeriod[0]), dayjs(monitoringPeriod[1])])
//         }
//         else {
//           rangeForm.setFieldValue("startEndDate", [null, null])
//         }
//       })

//       const handleMonitoringPeriodChange = (dates) => {
//         setMonitoringPeriod(dates);
//       };

//       const handleWeblinkChange = (event) => {
//         const value = event.target.value;
//         setWeblink(value);
//       };

//       const handleDeadlineDateChange = (date, dateString) => {
//         setFormData((prevData) => ({
//           ...prevData,
//           deadline_date: date ? date.format('YYYY-MM-DD') : '',
//         }));
//       };

//       const handleSubmit = async (e) => {
//         e.preventDefault();

//           const payload = {
//             ...formData,
//             project_id: projectid,
//             meth: methStatus,
//             monitoring_period: monitoringPeriod,
//             weblink: weblink,
//           };

//             try {
//               setLoading(true);
//               const response = await axios.post(
//                 `${update_project_details_url}`,
//                 payload,
//                 CONFIG_Token
//               );

//               setLoading(false);

//               if (!response.data.status) {
//                 toast.error(response.data.message);
//               } else {
//                 toast.success("Form Submitted Successfully");
//                 navigate("/dashboard");
//               }
//             } catch (error) {
//             }

//       };

//   return (
//     <>
//     <Header />
//       <SideNavbar />
//       <div className="content-wrapper bg-white p-5">

//         <div className="content">
//           <div className="container-fluid bg-white">

//             <div className="row">
//               <div className="col-12">

//               <h3 className="d-flex justify-content-center mb-5">Project Details</h3>

//                       <form onSubmit={handleSubmit} method="POST">
//                           <div className="row">
//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="projectname"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Project Name
//                                 <span style={{ color: "red" }}>*</span>
//                               </label>
//                               <Tooltip title={formData.project_name} placement="top">
//                               <input
//                                 type="text"
//                                 className={`form-control borderlightgreen ${error ? "is-invalid" : ""
//                                   }`}
//                                 id="project_name"
//                                 placeholder="Project Name"
//                                 required
//                                 name="project_name"
//                                 value={formData.project_name}
//                                 onChange={handleMyProjectChange}
//                               />
//                               </Tooltip>
//                               {error && (
//                                 <div className="error text-danger">{error}</div>
//                               )}
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="Scope(PO/POA)"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Scope(PA/POA)
//                                 <span style={{ color: "red" }}>*</span>
//                               </label>
//                               <select
//                                 id="scope_pa"
//                                 className="form-select borderlightgreen form-select-sm"
//                                 aria-label="Default select example"
//                                 name="scope_pa"
//                                 required
//                                 value={formData.scope_pa}
//                                 onChange={(e) =>
//                                   setFormData({
//                                     ...formData,
//                                     scope_pa: e.target.value,
//                                   })
//                                 }
//                               >
//                                 <option value={""}>Select</option>
//                                 {scope_PA_POA.map((option) => (
//                                   <option key={option.id} value={option.id}>
//                                     {option.scope}
//                                   </option>
//                                 ))}
//                               </select>
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="Program Id"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Program Id
//                               </label>
//                               <input
//                                 type="text"
//                                 id="program_id"
//                                 placeholder="Program Id"
//                                 name="program_id"
//                                 className={`form-control borderlightgreen ${programIdError ? "is-invalid" : ""
//                                   }`}
//                                 value={formData.program_id}
//                                 onChange={handleProgramIdChange}
//                               />
//                               {programIdError && (
//                                 <div className="invalid-feedback text-danger">
//                                   {programIdError}
//                                 </div>
//                               )}
//                             </div>

//                             </div>

//                             <div className="row">

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <div>
//                                 <label
//                                   htmlFor="Sectoral Scope"
//                                   className="form-label lightgreen fs-6"
//                                 >
//                                   SBU Head
//                                   <span style={{ color: "red" }}>*</span>
//                                 </label>

//                                 <select
//                                   id="sbu_head"
//                                   className="form-select borderlightgreen form-select-sm"
//                                   aria-label="Default select example"
//                                   name="sbu_head"
//                                   required
//                                   value={formData.sbu_head}
//                                   onChange={(e) =>
//                                     setFormData({
//                                       ...formData,
//                                       sbu_head: e.target.value,
//                                     })
//                                   }
//                                 >
//                                   <option value={""}>Select</option>
//                                   {trlist
//                                     .filter(
//                                       (option) => option.designation_id == 9
//                                     )
//                                     .map((option) => (
//                                       <option key={option.id} value={option.id}>
//                                         {option.name}
//                                       </option>
//                                     ))}
//                                 </select>
//                               </div>
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="Meth"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Meth

//                               </label>

//                               <Select
//                                 id="meth_id"
//                                 name="meth"
//                                 value={defaultMethName}
//                                 onChange={handleMethChange}
//                                 options={methOptions}
//                                 placeholder="Select"
//                                 isSearchable={true}
//                               />
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="deadline Date"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Deadline Date

//                               </label>

//                               <DatePicker
//                                 id="deadlinedate"
//                                 className={`form-control borderlightgreen custom-disabled-date-picker`}
//                                 style={{ color: 'black' }}

//                                 format="DD/MM/YYYY"
//                                 value={formData.deadline_date ? dayjs(formData.deadline_date) : null}
//                                 onChange={handleDeadlineDateChange}
//                                 disabledDate={(current) => current && current < moment().startOf('day')}
//                               />
//                             </div>

//                             </div>

//                            <div className="row">

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="Monitoring Period"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Monitoring Period
//                               </label>

//                               <NewForm form={rangeForm} >
//                                 <NewForm.Item
//                                   name="startEndDate"
//                                 >
//                                   <RangePicker
//                                     style={{ width: "100%" }}
//                                     format={'DD/MM/YYYY'}
//                                     onChange={handleMonitoringPeriodChange}
//                                   />
//                                 </NewForm.Item>
//                               </NewForm>

//                               {proposalDateError && (
//                                 <div className="invalid-feedback">
//                                   {proposalDateError}
//                                 </div>
//                               )}
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="weblink"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Weblink
//                               </label>

//                               <textarea
//                                 placeholder="Weblink"
//                                 className="form-control"
//                                 style={{ width: "100%" }}
//                                 id="weblink"
//                                 rows={3}
//                                 value={weblink}
//                                 onChange={handleWeblinkChange}
//                               />
//                             </div>

//                             <div className="col-lg-4 col-md-4 col-12 mb-3">
//                               <label
//                                 htmlFor="weblink"
//                                 className="form-label lightgreen fs-6"
//                               >
//                                 Additional Client Emails (Comma Seperated)
//                               </label>

//                               <textarea
//                                 placeholder="Additional Emails"
//                                 className="form-control"
//                                 style={{ width: "100%" }}
//                                 id="additional_emails"
//                                 rows={3}
//                                 value={formData.additional_emails}
//                                 onChange={(e) =>
//                                   setFormData({
//                                     ...formData,
//                                     additional_emails: e.target.value,
//                                   })
//                                 }
//                               />
//                             </div>

//                             </div>

//                             <div className="col-12 d-flex justify-content-end">
//                                 <button
//                                   type="submit"
//                                   className="btn btn-success mt-5"
//                                 >
//                                   Save
//                                 </button>
//                               </div>
//                           </form>

//             </div>
//             </div>

//           </div>
//         </div>
//       </div>

//       <Footer />
// </>
//   )
// }

// export default TLProposalChange

import { Form as NewForm } from "antd";
import axios from "axios";
import "../App.css";
import { Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  get_client_name_url,
  get_scope_pa_poa_url,
  get_meths_url,
} from "../config";
import { get_scope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_trsbu_url } from "../config";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Header from "../Pages/Header";
import {
  API_HEADER,
  get_project_details_url,
  update_project_details_url,
  get_contact_person_url,
} from "../config";
import { MultiSelect } from "react-multi-select-component";

const { RangePicker } = DatePicker;

const TLProposalChange = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size;

  const [projectid, setProjectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [myscope, setMyScope] = useState([]);
  const [program, setProgram] = useState([]);
  const [trlist, setTRList] = useState([]);
  const [myprojectName, setMyProjectName] = useState("");
  const [error, setError] = useState("");
  const [programId, setProgramId] = useState("");
  const [programIdError, setProgramIdError] = useState("");
  const [monitoringPeriod, setMonitoringPeriod] = useState([]);

  const [weblink, setWeblink] = useState("");

  const [proposalDateError, setProposalDateError] = useState("");

  const [myConsultant, setMyConsultant] = useState([]);
  const [consultant, setConsultant] = useState([]);

  const [clientContactPerson, setClientContactPerson] = useState([]);
  const [selectedclientContactPerson, setselectedclientContactPerson] =
    useState([]);

  const [apiData, setAPIData] = useState("");

  const [methOptions, setMethOptions] = useState([]);
  const [selectedMeth, setSelectedMeth] = useState(null);
  const [defaultMethName, setDefaultMethName] = useState(null);
  const [methStatus, setMethStatus] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [defaultClientName, setDefaultClientName] = useState(null);
  const [clientStatus, setClientStatus] = useState(null);
  const [defaultCountryName, setDefaultCountryName] = useState(null);
  const [scope_PA_POA, setScope_PA_POA] = useState([]);
  const [percent, setPercent] = useState(0);

  const [formData, setFormData] = useState({
    project_name: "",
    // country: "",
    program_id: "",
    scope_pa: "",
    meth: "",
    sbu_head: "",
    deadline_date: "",
    additional_emails: "",
  });

  const [rangeForm] = NewForm.useForm();

  useEffect(() => {
    const fetchDetails = async (id) => {
      try {
        const response = await axios.get(
          `${get_project_details_url}/${id}`,
          API_HEADER
        );

        if (response && response.data && response.data.record) {
          const { record } = response.data;
          setProjectId(record.id);
          setFormData({
            project_name: record.project_name,
            // country: record.country,
            program_id: record.program_id,
            scope_pa: record.scope_pa,
            meth: record.meth,
            sbu_head: record.sbu_head,
            deadline_date: record.deadline_date,
            additional_emails: record.additional_emails,
          });
          setMonitoringPeriod(record.monitoring_period);
          setWeblink(record.weblink);
          setSelectedMeth(record.meth);
          setMethStatus(record.meth);
          setProjectId(record.id);
          setAPIData(record);
          setSelectedClient(record.client_id);

          if (record?.monitoring_period) {
            setMonitoringPeriod(JSON.parse(record.monitoring_period));
          }
        }
      } catch (error) {}
    };
    fetchDetails(id);
  }, [id]);

  const CONFIG_Token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
  };

  const handleMyProjectChange = (event) => {
    const { value } = event.target;
    setMyProjectName(value);

    if (value.length < 3 || value.length > 500) {
      setError(
        "Project Name must be in alphabatic characters with 3 to 500 characters"
      );
    } else {
      setError("");
    }
    setFormData({ ...formData, project_name: event.target.value });
  };

  const handleProgramIdChange = (event) => {
    const value = event.target.value;
    setProgramId(value);

    if (!/^[a-zA-Z0-9]{3,40}$/.test(value)) {
      setProgramIdError(
        "Earthood Id must be alphanumeric and between 3 to 40 characters"
      );
    } else {
      setProgramIdError("");
    }
    setFormData({ ...formData, program_id: event.target.value });
  };

  useEffect(() => {
    const fetchClientOptions = async () => {
      try {
        const response = await axios.get(`${get_client_name_url}`);
        const data = response.data;
        setClientOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultClient = data.data
          .filter((item) => item.id === selectedClient)
          .map((client) => ({ value: client.id, label: client.name }));
        setDefaultClientName(defaultClient[0]);
      } catch (error) {}
    };

    fetchClientOptions();
  }, [selectedClient]);

  const handleMethChange = (selectedOption) => {
    setSelectedMeth(selectedOption);
    setMethStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) {}
    };

    fetchDataTrData();
  }, []);

  useEffect(() => {
    const fetchMethOptions = async () => {
      try {
        const response = await axios.get(`${get_meths_url}`);
        const data = response.data;
        setMethOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultMeth = data.data
          .filter((item) => item.id == selectedMeth)
          .map((meth) => ({ value: meth.id, label: meth.name }));
        setDefaultMethName(defaultMeth[0]);
      } catch (error) {}
    };

    fetchMethOptions();
  }, [selectedMeth]);

  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);

        setMyScope(
          responsescope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.sector_name,
          }))
        );
      } catch (error) {}
    };
    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) {}
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) {}
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataConsultant = async () => {
      try {
        const responseconsultant = await axios.get(`${get_client_name_url}`);
        setConsultant(
          responseconsultant.data.data.map((consultant) => ({
            value: consultant.id,
            label: consultant.name,
          }))
        );
      } catch (error) {}
    };

    fetchDataConsultant();
  }, []);

  useEffect(() => {
    if (apiData && consultant.length > 0) {
      const consultantName = apiData?.consultant_id?.split(",").map(Number);

      const filteredConsultant = consultant.filter((expert) =>
        consultantName?.includes(expert.value)
      );

      setMyConsultant(filteredConsultant);
    }
  }, [apiData, consultant]);

  useEffect(() => {
    const fetchDataClientContactPerson = async () => {
      try {
        let payload = {
          id: selectedClient,
        };

        const responseclientcontactperson = await axios.post(
          `${get_contact_person_url}`,
          payload
        );
        const formattedClientContactPerson = JSON.parse(
          responseclientcontactperson.data.data.contact_person
        ).map((person) => ({
          value: person.id,
          label: person.name,
        }));

        setClientContactPerson(formattedClientContactPerson);

        const clientcontactperson =
          apiData.contact_person_id?.split(",").map(Number) || [];
        // const filteredClientContact = formattedClientContactPerson.filter((clientcontact) =>
        //   clientcontactperson?.(clientcontact.value)
        // );

        const filteredClientContact = formattedClientContactPerson.filter(
          (clientcontact) => clientcontactperson?.includes(clientcontact.value)
        );

        setselectedclientContactPerson(filteredClientContact);
      } catch (error) {}
    };
    if (selectedClient) {
      fetchDataClientContactPerson();
    }
  }, [selectedClient, apiData]);

  useEffect(() => {
    if (monitoringPeriod && monitoringPeriod.length > 0) {
      rangeForm.setFieldValue("startEndDate", [
        dayjs(monitoringPeriod[0]),
        dayjs(monitoringPeriod[1]),
      ]);
    } else {
      rangeForm.setFieldValue("startEndDate", [null, null]);
    }
  });

  const handleMonitoringPeriodChange = (dates) => {
    setMonitoringPeriod(dates);
  };

  const handleWeblinkChange = (event) => {
    const value = event.target.value;
    setWeblink(value);
  };

  const handleDeadlineDateChange = (date, dateString) => {
    setFormData((prevData) => ({
      ...prevData,
      deadline_date: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const consultant = myConsultant.map((value) => value.value);
    const contactperson = selectedclientContactPerson.map(
      (value) => value.value
    );

    const payload = {
      ...formData,
      project_id: projectid,
      meth: methStatus,
      monitoring_period: monitoringPeriod,
      weblink: weblink,
      consultant_id: consultant,
      contact_person_id: contactperson,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${update_project_details_url}`,
        payload,
        CONFIG_Token
      );

      setLoading(false);

      if (!response.data.status) {
        toast.error(response.data.message);
      } else {
        toast.success("Form Submitted Successfully");
        navigate("/dashboard");
      }
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white p-5">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row">
              <div className="col-12">
                <h3 className="d-flex justify-content-center mb-5">
                  Project Details
                </h3>

                <form onSubmit={handleSubmit} method="POST">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="projectname"
                        className="form-label lightgreen fs-6"
                      >
                        Project Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Tooltip title={formData.project_name} placement="top">
                        <input
                          type="text"
                          className={`form-control borderlightgreen ${
                            error ? "is-invalid" : ""
                          }`}
                          id="project_name"
                          placeholder="Project Name"
                          required
                          name="project_name"
                          value={formData.project_name}
                          onChange={handleMyProjectChange}
                        />
                      </Tooltip>
                      {error && (
                        <div className="error text-danger">{error}</div>
                      )}
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="Scope(PO/POA)"
                        className="form-label lightgreen fs-6"
                      >
                        Scope(PA/POA)
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="scope_pa"
                        className="form-select borderlightgreen form-select-sm"
                        aria-label="Default select example"
                        name="scope_pa"
                        required
                        value={formData.scope_pa}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            scope_pa: e.target.value,
                          })
                        }
                      >
                        <option value={""}>Select</option>
                        {scope_PA_POA.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.scope}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="Program Id"
                        className="form-label lightgreen fs-6"
                      >
                        Program Id
                      </label>
                      <input
                        type="text"
                        id="program_id"
                        placeholder="Program Id"
                        name="program_id"
                        className={`form-control borderlightgreen ${
                          programIdError ? "is-invalid" : ""
                        }`}
                        value={formData.program_id}
                        onChange={handleProgramIdChange}
                      />
                      {programIdError && (
                        <div className="invalid-feedback text-danger">
                          {programIdError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <div>
                        <label
                          htmlFor="Sectoral Scope"
                          className="form-label lightgreen fs-6"
                        >
                          SBU Head
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <select
                          id="sbu_head"
                          className="form-select borderlightgreen form-select-sm"
                          aria-label="Default select example"
                          name="sbu_head"
                          required
                          value={formData.sbu_head}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              sbu_head: e.target.value,
                            })
                          }
                        >
                          <option value={""}>Select</option>
                          {trlist
                            .filter((option) => option.designation_id == 9)
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="Meth"
                        className="form-label lightgreen fs-6"
                      >
                        Meth
                      </label>

                      <Select
                        id="meth_id"
                        name="meth"
                        value={defaultMethName}
                        onChange={handleMethChange}
                        options={methOptions}
                        placeholder="Select"
                        isSearchable={true}
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="deadline Date"
                        className="form-label lightgreen fs-6"
                      >
                        Deadline Date
                      </label>

                      <DatePicker
                        id="deadlinedate"
                        className={`form-control borderlightgreen custom-disabled-date-picker`}
                        style={{ color: "black" }}
                        format="DD/MM/YYYY"
                        value={
                          formData.deadline_date
                            ? dayjs(formData.deadline_date)
                            : null
                        }
                        onChange={handleDeadlineDateChange}
                        disabledDate={(current) =>
                          current && current < moment().startOf("day")
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="Monitoring Period"
                        className="form-label lightgreen fs-6"
                      >
                        Monitoring Period
                      </label>

                      <NewForm form={rangeForm}>
                        <NewForm.Item name="startEndDate">
                          <RangePicker
                            style={{ width: "100%" }}
                            format={"DD/MM/YYYY"}
                            onChange={handleMonitoringPeriodChange}
                          />
                        </NewForm.Item>
                      </NewForm>

                      {proposalDateError && (
                        <div className="invalid-feedback">
                          {proposalDateError}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="consultant"
                        className="form-label lightgreen fs-6"
                      >
                        Consultant Name
                      </label>

                      <MultiSelect
                        options={consultant}
                        value={myConsultant}
                        onChange={setMyConsultant}
                        labelledBy="Select"
                        // className={formEdit ? "multi" : ""}
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <div>
                        <label
                          htmlFor="contact person"
                          className="form-label lightgreen fs-6"
                        >
                          Client Contact Person
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <MultiSelect
                          options={clientContactPerson}
                          value={selectedclientContactPerson}
                          onChange={setselectedclientContactPerson}
                          labelledBy="Select"
                          // className={formEdit ? "multi" : ""}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="weblink"
                        className="form-label lightgreen fs-6"
                      >
                        Weblink
                      </label>

                      <textarea
                        placeholder="Weblink"
                        className="form-control"
                        style={{ width: "100%" }}
                        id="weblink"
                        rows={3}
                        value={weblink}
                        onChange={handleWeblinkChange}
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                      <label
                        htmlFor="weblink"
                        className="form-label lightgreen fs-6"
                      >
                        Additional Client Emails (Comma Seperated)
                      </label>

                      <textarea
                        placeholder="Additional Emails"
                        className="form-control"
                        style={{ width: "100%" }}
                        id="additional_emails"
                        rows={3}
                        value={formData.additional_emails}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            additional_emails: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-success mt-5">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TLProposalChange;
