import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER,workload_stage_wise_url } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ValidatorWorkloadGraph({search,startDate,endDate})
{
    const navigate = useNavigate();

   const [projectUndergoing, setProjectUndergoing]= useState([]);
   const [projectCompleted, setProjectCompleted]= useState([]);
   const [validatorName, setValidatorName] = useState([]);
   const [programWiseData, setProgramWiseData] = useState([]);
   const [projectRFI, setprojectRFI]= useState([]);
   const [projectKickout, setprojectKickout]= useState([]);

   

   useEffect( ()=>{
       const projectundergoing=[];
       const projectcompleted=[];
       const validatorname=[];
       const projectrfi=[];
       const projectKickout=[];

       const getProposalScopeWise= async()=>{
    
        const payload = { 
            "fy": "",
            'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
            'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
        };

       const reqData= await axios.post(`${workload_stage_wise_url}`, payload,API_HEADER);
       const MyprogramwiseData = reqData.data.validator_worklaods
       
       for(let i=0; i< MyprogramwiseData?.length; i++)
       {        
        projectundergoing.push(((MyprogramwiseData[i]?.project_undergoing)));
        projectcompleted.push(((MyprogramwiseData[i]?.project_completed)));
        validatorname.push(((MyprogramwiseData[i]?.name))); 
        projectrfi.push(((MyprogramwiseData[i].project_rfi)));
        projectKickout.push(((MyprogramwiseData[i].project_kickout)));     
       }
       
       setProjectUndergoing(projectundergoing);
       setProjectCompleted(projectcompleted);
       setValidatorName(validatorname);
       setProgramWiseData(MyprogramwiseData);
       setprojectRFI(projectrfi);
       setprojectKickout(projectKickout);

       }

       getProposalScopeWise();

   },[search]);


   const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {

    const seriesName = w.globals.seriesNames[seriesIndex];

    const programName = w.globals.labels[dataPointIndex];

    const matchedProgram = programWiseData.find((program) => program.name === programName);
 
    const validator_verifier  = matchedProgram ? matchedProgram.validator_verifier : null;

    const title = w.globals.seriesNames[seriesIndex];

    if (validator_verifier) {
      navigate('/programwiseprojecttable', { state: { title,workload_status:seriesName, validator_verifier, programName} });
    }
  };


   return(
    <React.Fragment>
        <div className="container-fluid">
           
            <Chart
            type="bar"
            width={"100%"}
            height={400}
            series={[
                {
                    name:"Ongoing Projects",
                    data: projectUndergoing,
                    color: '#2776ae'
                },

                {
                    name:"RFI Completed",
                    data: projectRFI,
                    color: '#65ddf7'
                },

                {
                    name:"Under Kickout",
                    data: projectKickout,
                    color: '#f37324'
                },
                
                {
                    name:"Issued/Registered",
                    data: projectCompleted,
                    color: '#08415C'
                },
                
                
            ]}

            options={{
                // title:{
                //     text:"Proposal Month Wise"
                // },
                chart:{
                    toolbar: {
                        show: false,  // This will hide the icons on the top right corner
                      },
                    stacked:true,
                    events: {
                        dataPointSelection: handleChartClick,
                      },
                },
                plotOptions:{
                    bar:{
                        horizontal:false,
                        columnWidth:'40%',
                        dataLabels : {
                            total: {
                                enabled: true,
                                style: {
                                  fontSize: '11px',
                                  fontWeight: 900
                                },
                                
                              }
                          }
                      }
                },
                stroke: {
                    width: 1,
                },
                xaxis:{
                    title:{
                        text:"Validators/Verifiers",
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold'
                        }
                    },
                    categories : validatorName
                },
                yaxis:{
                    title:{
                        text:"Number of Projects",
                        style:{fontSize:'16px',fontWeight:'bold'}
                    },
                    labels:{
                        style: { fontSize: "15"},

                    }
                },
                legend:{
                    position: 'bottom',
                },
                dataLabels:{
                    enabled:false,
                },
                grid: {
                    show:true,
                    xaxis:{
                        lines:{
                            show:false
                        }
                    },
                    yaxis:{
                        lines:{
                            show:false
                        }
                    },
                   

                },
                grid: {
                    borderColor: '#f1f1f1',
                    row: {
                      colors: ['transparent'], 
                      opacity: 1,
      
                    },
                  },

            }}

            />
        </div>
    </React.Fragment>
);
}
export default ValidatorWorkloadGraph;


// import React from "react";

// function ProjectStageWise({ startDate, endDate }) {
//   const grafanaBaseUrl = "http://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
//   const orgId = 1;
//   const timezone = "browser";
//   const panelId = 10;
//   const theme = "light";

//   // Convert start and end date to UNIX timestamps (milliseconds)
//   const from = startDate ? new Date(startDate).getTime() : Date.now() - 86400000; // Default: 24 hours ago
//   const to = endDate ? new Date(endDate).getTime() : Date.now(); // Default: now

//   // Construct Grafana iframe URL dynamically
//   const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

//   return (
//     <div className="container-fluid">
//       <iframe
//         src={grafanaUrl}
//         width="100%"
//         height="400"
//         frameBorder="0"
//         title="Program Wise Projects"
//         style={{ border: "none" }}
//       ></iframe>
//     </div>
//   );
// }

// export default ProjectStageWise;