
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  BASE_DOCUMENT,
} from "../../../config";
import { get_KickoutInfo_url, tm_kickout_rfi_url } from "../../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../../config";

const TMKickoutRFIRound = (props) => {

  const { id } = useParams();
  const { isTL } = props;

  const [remark, setRemark] = useState("");
  const [remarkTMRFI, setRemarkTMRFI] = useState("");
  const [remarkTLRFI, setRemarkTLRFI] = useState("");
  const [remarkATRFI, setRemarkATRFI] = useState("");
  const [action, setAction] = useState("");
  const [fvrName, setFVRName] = useState(null)
  const [fvr, setFvr] = useState("")
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null)
  const [auditFinding, setAuditFinding] = useState("")
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");

  const [tmrfiStatus, setTmrfiStatus] = useState("");
  const [tmRfiStatus, setTmRfiStatus] = useState("");
  const [remarkMD, setRemarkMD] = useState("");


  const [loading, setLoading] = useState(false);

  const [otherDocName, setOtherDocName] = useState(null);
  const [otherDoc, setOtherDoc] = useState([]);


  const [kickoutStatus, setkickoutStatus] = useState("");
  const [kickoutId, setKickoutId] = useState('');

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { kickout } = response.data;

      setKickoutId(kickout.id)
      setkickoutStatus(record.kickout_status);

      setRemarkTLRFI(kickout.rfi_tl_remarks);
      setRemarkTLRFI(kickout.rfi_at_remarks)
      setRemarkTMRFI((kickout.kickout_status == 27) ? kickout.rfi_tmremarks = "" : kickout.rfi_tmremarks)

      setTmrfiStatus(kickout.status);
      setTmRfiStatus(kickout.status);
      setRemarkMD(kickout.rfi_mdremarks);

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.rfi_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_err_document}`;
      setERName(url2);
      setER(kickout.rfi_err_document);


      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.rfi_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.rfi_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_irr_document}`;
      setRRName(url5);
      setRR(kickout.rfi_irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.rfi_audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_other_docs}`;
      setOtherName(url7);
      setOther(kickout.rfi_other_docs);


    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const combined = otherDoc?.map((name, index) => ({
    name: name,
    url: otherDocName[index]
  }));


  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };


  const handleRemarksChange = (content) => {
    setRemarkTMRFI(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmitAction = async (e) => {
    e.preventDefault();
    if ((remarkTMRFI == null || remarkTMRFI == '<p><br></p>' || remarkTMRFI == '') && action) {
      toast.error('Please Add Some Remarks')
    }
    else {
      try {

        setLoading(true);
        let payload = {
          project_id: id,
          kickout_status: action,
          rfi_tmremarks: remarkTMRFI,
          kickout_id: kickoutId,

        };
        const response = await axios.post(`${tm_kickout_rfi_url}`, payload, API_HEADER);
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemark("");
          setAction("");
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <form
        method="post"
        encType="json/form-data"
        onSubmit={handleSubmitAction}
      >
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                S.No
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                Document Name
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                Uploaded Document
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>

              <td className="text-center lightgreen p-3">
                FVR Report
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={fvrName} target="_blank">{fvr}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">2</td>

              <td className="text-center lightgreen p-3">
                ER
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={erName} target="_blank">{er}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">3</td>

              <td className="text-center lightgreen p-3">
                PDD/MR
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={pddName} target="_blank">{pdd}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">4</td>

              <td className="text-center lightgreen p-3">
                IRR
              </td>

              <td>
                <a href={rrName}>{rr}</a>
              </td>



            </tr>

            <tr>
              <td class="text-center">5</td>

              <td className="text-center lightgreen p-3">
                CPA
              </td>

              <td>
                <a href={cpaName}>{cpa}</a>
              </td>


            </tr>

            <tr>
              <td class="text-center">6</td>

              <td className="text-center lightgreen p-3">
                Audit Finding
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={auditFindingName} target="_blank">{auditFinding}</a>
              </td>
            </tr>


            <tr>
              <td class="text-center">7</td>


              <td className="text-center lightgreen p-3">
                Other Document
              </td>

              <td>
                <a target="_blank" href={otherName}>
                  {other}
                </a>
              </td>


            </tr>

          </tbody>
        </table>
        {kickoutStatus === 27 ? (
          <>
            {remarkTLRFI &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTLRFI}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}

            {remarkATRFI &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkATRFI}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}
            <div

              style={{ marginTop: "60px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="approve"
                    name="rfi"
                    value={29}
                    onChange={handleActionChange}
                    className=" text-success"
                  />
                  <label className="text-success mx-1" htmlFor="approve">
                    Approved
                  </label>
                </span>
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="clarification_required"
                    name="rfi"
                    value={28}
                    onChange={handleActionChange}
                    className="text-danger"
                  />
                  <label
                    className="text-danger mx-1"
                    htmlFor="clarification_required"
                  >
                    Clarification Required
                  </label>
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
              <div className="container my-4 d-flex justify-content-end pr-0">
                <button
                  className="btn my-4 px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        ) : kickoutStatus === 29 || kickoutStatus == 28 ? (
          <>
            {remarkTLRFI &&
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTLRFI}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}

            {remarkATRFI &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkATRFI}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>}
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <span>
                  {kickoutStatus === 29 ? (
                    <Alert
                      className="col-12"
                      message="Approved"
                      type="success"
                      showIcon
                    />
                  ) : kickoutStatus === 28 ? (
                    <Alert
                      className="col-12"
                      message="Clarification Required"
                      banner
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="row mt-4">
                <div >
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (kickoutStatus === 31 || kickoutStatus === 30) ? (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span>
                  {kickoutStatus === 31 ? (
                    <Alert
                      className="col-12"
                      message="Approved"
                      type="success"
                      showIcon
                    />
                  ) : kickoutStatus === 30 ? (
                    <Alert
                      className="col-12"
                      message="Clarification Required"
                      banner
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {loading && <div className="loader"></div>}
      </form>
    </>
  );
};

export default TMKickoutRFIRound;
