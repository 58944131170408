import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, dvr_report_url, tr_action_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../config";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";




const ATDvr = (props) => {


  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const [program, setProgram] = useState([]);
  const [action, setAction] = useState("");
  // const [remark, setRemark] = useState("");
  const [remarkTR, setRemarkTR] = useState("");
  const [tlremark, setTLRemark] = useState("");
  const [atremark, setATRemark] = useState("");
  const [remarkClient, setRemarkClient] = useState("");
  const [member, setMember] = useState();
  const [dvrStatus, setDvrStatus] = useState("");
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [fileDVRSizeError, setFileDVRSizeError] = useState(false);
  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [
    filetechnicalreviewsheetSizeError,
    setFiletechnicalreviewsheetSizeError,
  ] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [technicalReviewSheetName, setTechnicalReviewSheetName] =
    useState(null);
  const [technicalReviewSheet, setTechnicalReviewSheet] = useState("");
  const [dvrName, setDVRName] = useState(null);
  const [dvr, setDvr] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");

  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [error, setError] = useState('')

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [dateError, setDateError] = useState('')

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);


  const fetchData = async (ids) => {

    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;
      setTeamList(teams);
      setProgram(record.program);


      setDvrStatus(record.dvr_status);

      setMember(record.dvr_status);

      setATRemark((record.dvr_status == 0 || record.dvr_status == 1) ? record.dvr_at_remarks = "" : record.dvr_at_remarks)
      setTLRemark(record.dvr_tl_remarks)
      setAction(record.dvr_status);
      setStartDate(record.dvr_starting_date);
      setEndDate(record.dvr_completion_date);
      setFile1(record.dvr_report)
      setFile3(record.tr_er_doc)

      setFile4(record.tr_pdd_doc)

      setFile5(record.earthood_report)




      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.dvr_report}`;
      setDVRName(url1);
      setDvr(record.dvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_cpa_doc}`;
      setCpaName(url2);
      setCpa(record.tr_cpa_doc);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
      setERName(url3);
      setER(record.tr_er_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
      setPDDName(url4);
      setPdd(record.tr_pdd_doc);

      let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.earthood_report}`;
      setTechnicalReviewSheetName(url5);
      setTechnicalReviewSheet(record.earthood_report);

      let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_irr_doc}`;
      setRRName(url6);
      setRR(record.tr_irr_doc);


    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  // useEffect(() => {
  //   setMember(isTL ? 2 : 4);
  // }, [isTL]);


  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };


  const handleRemarksChange = (content) => {
    setATRemark(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDate(value);
  }
  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDate(value);
  }

  const getCurrentDate = () => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();

    // Add leading zero if month or day is less than 10
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    // Return the date in the format yyyy-mm-dd
    return `${year}-${month}-${day}`;
  };

  const validateDates = (dateFrom, dateTo) => {
    if (dateTo && dateFrom && dateTo <= dateFrom) {
      setDateError("End Date must be greater than or equal to Start Date");
    } else {
      setDateError('');
    }
  };


  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);


    if ((atremark == null || atremark == "<p><br></p>" || atremark == "") || (file1 == null || file1 == '') || (file3 == null || file3 == '') || (file4 == null || file4 == '') || (file5 == null || file5 == '')) {
      toast.error("Please upload the document and remarks.");
    } else {
      // else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("dvr_report", file1);
        formDataToSend.append("tr_cpa_doc", file2);
        formDataToSend.append("tr_er_doc", file3);
        formDataToSend.append("tr_pdd_doc", file4);
        formDataToSend.append("earthood_report", file5);
        formDataToSend.append("tr_irr_doc", file6);
        formDataToSend.append("dvr_status", member);
        formDataToSend.append("dvr_at_remarks", atremark);
        formDataToSend.append('dvr_starting_date', startDate)
        formDataToSend.append('dvr_completion_date', endDate)


        const response = await axios.post(
          `${dvr_report_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (
          fileDVRSizeError ||
          fileCpaSizeError ||
          filePDDSizeError ||
          filetechnicalreviewsheetSizeError ||
          fileRRSizeError ||
          fileERSizeError
        ) {
          toast.error(`File size must below ${dynamicMaxSize}`);
        } else {
          toast.success("Document Submitted Successfully");
          setFile1(null);
          setFile2(null);
          setFile3(null);
          setFile4(null);
          setFile5(null);
          setFile6(null);
          setMember("");
          setATRemark("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }
    }
  };


  return (
    <>
      {(dvrStatus == 0 || dvrStatus === 1 || dvrStatus == null) ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Upload Document
                  </th>
                  {dvrStatus === 1 ? (
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td className="text-center lightgreen p-3">
                    DVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  {dvrStatus === 1 ? (

                    <FileUploaderComponent file={file1} setFile={setFile1} />

                  ) : (

                    <FileUploaderComponent file={file1} setFile={setFile1} />

                  )}


                  {dvrStatus === 1 ? (
                    <td>
                      <a href={dvrName} target="_blank">
                        {dvr}
                      </a>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td class="text-center">2</td>
                  <td className="text-center lightgreen p-3">
                    PDD / MR
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  {dvrStatus === 1 ? (

                    <FileUploaderComponent file={file4} setFile={setFile4} />

                  ) : (

                    <FileUploaderComponent file={file4} setFile={setFile4} />

                  )}


                  {dvrStatus === 1 ? (
                    <td>
                      <a href={pddName} target="_blank">
                        {pdd}
                      </a>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <td class="text-center">3</td>
                  <td className="text-center lightgreen p-3">
                    ER<span style={{ color: "red" }}>*</span>
                  </td>

                  {dvrStatus == 1 ? (


                    <FileUploaderComponent file={file3} setFile={setFile3} />

                  ) : (

                    <FileUploaderComponent file={file3} setFile={setFile3} />

                  )}


                  {dvrStatus == 1 ? (
                    <td>
                      <a href={erName}>{er}</a>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <td class="text-center">4</td>
                  {dvrStatus == 1 ? (
                    <td className="text-center lightgreen p-3">IRR</td>
                  ) : (
                    <td className="text-center lightgreen p-3">IRR</td>
                  )}

                  {dvrStatus == 1 ? (

                    <FileUploaderComponent file={file6} setFile={setFile6} />

                  ) : (

                    <FileUploaderComponent file={file6} setFile={setFile6} />

                  )}


                  {dvrStatus == 1 ? (
                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <td class="text-center">5</td>
                  {dvrStatus == 1 ? (
                    <td className="text-center lightgreen p-3">CPA</td>
                  ) : (
                    <td className="text-center lightgreen p-3">CPA</td>
                  )}

                  {dvrStatus == 1 ? (

                    <FileUploaderComponent file={file2} setFile={setFile2} />

                  ) : (

                    <FileUploaderComponent file={file2} setFile={setFile2} />

                  )}

                  {dvrStatus == 1 ? (
                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <td class="text-center">6</td>
                  {/* {dvrStatus == 1 ? (
                  <td className="text-center lightgreen p-3">
                    Technical Review Sheet
                  </td>
                ) : ( */}
                  <td className="text-center lightgreen p-3">
                    Technical Review Sheet
                    <span style={{ color: "red" }}>*</span>
                  </td>
                  {/* )} */}

                  {dvrStatus == 1 ? (

                    <FileUploaderComponent file={file5} setFile={setFile5} />

                  ) : (

                    <FileUploaderComponent file={file5} setFile={setFile5} />

                  )}

                  {dvrStatus == 1 ? (
                    <td>
                      <a href={technicalReviewSheetName} target="_blank">
                        {technicalReviewSheet}
                      </a>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-5 my-2">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value=''>Select</option>
                  <option value={2}>Forward to TL</option>
                  <option value={3}>Send to TR</option>

                </select>
              </div>
            </div>

            <div className="row mb-2 mt-4 ">
              <div className="col-6 mb-3">
                <label htmlFor="startdate" className="form-label lightgreen fs-6">
                  Start Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="start_date"
                  required
                  name="start_date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  min={getCurrentDate()}
                />

              </div>
              <div className="col-6 mb-3">
                <label htmlFor="enddate" className="form-label lightgreen fs-6">
                  Likely Completion Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="end_date"
                  required
                  name="end_date"
                  value={endDate}
                  // onChange={handleEndDateChange}
                  // min={getCurrentDate()} // Set the min attribute to disable past dates

                  onChange={startDate ? handleEndDateChange : undefined} // Only allow changing when startDate is set
                  min={startDate ? startDate : undefined} // Set min to startDate if startDate is set
                  disabled={!startDate}
                />
              </div>
            </div>


            <div className="mb-3">



              <div>
                <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks<span style={{ color: "red" }}>*</span></p>
                <hr className="mb-3 lightgreen" />
                <div>
                  <ReactQuill
                    theme="snow"
                    value={atremark}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: atremark }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              {tlremark &&
                <div className="mb-3">
                  <div>
                    <p className="lightgreen fw-bold my-3 fs-6"> Team Leader Remarks</p>
                    <hr className="mb-3 lightgreen" />
                    <div>
                      <ReactQuill
                        theme="snow"
                        value={tlremark}
                        name="remarks"
                        readOnly={true}
                        disabled
                        dangerouslySetInnerHTML={{ __html: tlremark }}
                        // onChange={handleRemarksChange}
                        style={{
                          height: "20vh",
                          marginBottom: "2rem",
                        }}
                        className="mb-5"
                      />
                    </div>
                  </div>
                </div>}



            </div>
            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5 mt-4"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      ) :

        (
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Uploaded Document
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td className="text-center lightgreen p-3">
                    DVR Report
                    <span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={dvrName} target="_blank">
                      {dvr}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    PDD / MR<span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={pddName}>{pdd}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    ER<span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={erName}>{er}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>

                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>

                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>
                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Technical Review Sheet<span style={{ color: "red" }}>*</span>
                  </td>

                  <td>
                    <a href={technicalReviewSheetName}>{technicalReviewSheet}</a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-5 my-3">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={2}
                  disabled={true}
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value=''>Select</option>
                  <option value={2}>Forward to TL</option>
                  <option value={3}>Send to TR</option>

                </select>
              </div>
            </div>

            <div className="row mb-2 mt-4 ">
              <div className="col-6 mb-3">
                <label htmlFor="startdate" className="form-label lightgreen fs-6">
                  Start Date <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="start_date"
                  disabled
                  name="start_date"
                  value={startDate}

                />

              </div>
              <div className="col-6 mb-3">
                <label htmlFor="enddate" className="form-label lightgreen fs-6">
                  Likely Completion Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="end_date"
                  disabled
                  name="end_date"
                  value={endDate}

                />
                {dateError && <div className="text-danger">{dateError}</div>}
              </div>
            </div>





            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={atremark}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: atremark }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            {tlremark &&
              <div className="mb-3">
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6"> Team Leader Remarks</p>
                  <hr className="mb-3 lightgreen" />
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={tlremark}
                      name="remarks"
                      readOnly={true}
                      disabled
                      dangerouslySetInnerHTML={{ __html: tlremark }}
                      // onChange={handleRemarksChange}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              </div>}

            {loading && <div className="loader"></div>}

          </form>
        )

      }
    </>
  );
};

export default ATDvr;
