import React, { useEffect, useState } from 'react'
import '../App.css';
import PTDash from '../Components/Dashboard/PTDash';
import TMSDash from '../Components/Dashboard/TMSDash';
import TDash from '../Components/Dashboard/TDash';
import SDash from '../Components/Dashboard/SDash';
import CDash from '../Components/Dashboard/CDash';
import AT_TLDash from '../Components/Dashboard/AT_TLDash';
import Header from './Header';
import SideNavbar from '../Components/SideNavbar';
import Footer from '../Components/Footer';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import axios from 'axios';
import { API_HEADER, getClientDetails, getCountryList, saveClient, get_regions_url, mail_reminder_url } from '../config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SBUDash from '../Components/Dashboard/SBUDash';
import TRDash from '../Components/Dashboard/TRDash';
import MDDash from '../Components/Dashboard/MDDash';
import RegistrarDash from '../Components/Dashboard/RegistrarDash';
import MultiRoleBased from './MultiRoleBased';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/bootstrap.css';
import GeographicalAnalyticsDash from '../Components/Dashboard/GeographicalAnalyticsDash';

export default function Dashboard({ showAddClientButton }) {

  let [username, SetUserName] = useState('');
  let [designation_id, SetDesignation] = useState(0);
  let [modalVisible, SetModalVisible] = useState(false);
  let [callApi, SetCallApi] = useState(false);
  let [formdisable, SetFormDisabled] = useState(false);
  let [multiUser, setMultiUser] = useState(0);

  const { Option } = Select;

  const [clientform] = Form.useForm();
  const [continent_list, setContinentList] = useState([]);
  const [region, setRegion] = useState([]);
  const [country_list, setCountryList] = useState([]);
  let [client_id, SetClientId] = useState(null);

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
 

  let navigate = useNavigate();


  useEffect(function () {
    const name = sessionStorage.getItem('name');
    SetUserName(name)
  }, [])

  useEffect(function () {
    const id = sessionStorage.getItem('designation_id');
    const multi_user = sessionStorage.getItem('multi_user');
    setMultiUser(multi_user);
    SetDesignation(parseInt(id))
  }, [])

  useEffect(function () {
    const MAILREMINDER = async () => {
      try {
          
        const mail_reminder = await axios.get(`${mail_reminder_url}`);
        localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
            
      }
       catch (error) {
          toast.error(error);
      }
     
  };
  MAILREMINDER()
  }, [])

  const getCountry = async (value) => {

    try {

      const result = await axios.get(`${getCountryList}?region_id=${value}`);
      setCountryList(result.data.data);
    } catch (error) {
    }

  }
  const getContinent = async () => {

    try {
      const result = await axios.get(`${get_regions_url}`);
      setContinentList(result.data.data);
    } catch (error) {

    }

  }


  const handleRegion = (value) => {
    setRegion(value);
    getCountry(value);
  }

  return (
    <>
        <Header/>
        <SideNavbar/>
        <div className="content-wrapper bg-white ">
          <div className="content">
            <div className="container-fluid bg-white ">
              <div className="row">
                <div className="col-12">
                
              <div className="d-flex justify-content-between align-items-center my-4">
                <div className=''>
                  <h5 className='textcolorblue textwelcome'>Welcome Back, {username}</h5>
                </div>
                <div className="d-flex gap-2 align-items-center">
                
                </div>
            </div>
            </div>


        {(() => {
          if(multiUser==0){
          if (designation_id === 6) {
            return <PTDash callApi={callApi} />
          } else if (designation_id === 3) {
            return <TDash />
          }else if (designation_id === 4) {
            return <TMSDash />
          }
           else if(designation_id==5){
            return <SDash />
          }
          else if(designation_id==13){
            return <CDash/>
          }
          else if(designation_id==8){
            return <AT_TLDash/>
          }
          else if(designation_id==9){
          return <SBUDash/>
          }
          else if(designation_id==7){
            return <TRDash/>
          }
          else if(designation_id==2){
            return <MDDash/>
          }
          else if(designation_id==11){
            return <RegistrarDash/>
          }
          else if(designation_id==14){
            return <GeographicalAnalyticsDash/>
          }
        }else{
          return <MultiRoleBased/>
        }
        })()}
      
                </div>
            </div>
          </div>
        </div>
        <Footer />
      {/* </div> */}
    </>
  )
}
