// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import { API_HEADER, project_stage_wise_url } from "../../../config";
// import axios from "axios";
// import { toast } from 'react-toastify';
// import { useNavigate } from "react-router-dom";

// function ProjectStageWise({search,startDate,endDate}) {
//     const [projectwisestage, setProjectWiseStage] = useState({});
//     const [programWiseData, setProgramWiseData] = useState([]); // State to store MyprogramwiseData
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
                
//                 const payload = { 
//                     "fy": "",
//                     'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//                     'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//                 };

//                 const response = await axios.post(`${project_stage_wise_url}`, payload, API_HEADER);
//                 setProjectWiseStage(response.data.data);
//                 setProgramWiseData(response.data.data); // Save the data
//             } catch (error) {
//                 toast.error(error);
//             }
//         };
//         fetchData();
//     }, [search]);

//     const customColors = [
//         "#fe705d", "#ffb7c5", "#6b59cd", "#00cc99",
//         "#008081", "#edce7a", "#ee7036", "#178ab7",
//         "#a71666", "#d31638", "#4fb19d", "#823772"
//     ];

//     const formatLabel = (label) => {
//         return label.split('_')
//             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//             .join(' ');
//     };

//     const formattedLabels = Object.keys(projectwisestage).map(formatLabel);

//     const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
//         const project_stage = w.globals.labels[dataPointIndex];
//         const title = w.globals.seriesNames[seriesIndex]
//         const seriesName = w.globals.seriesNames[seriesIndex]

//         if (project_stage) {
//             navigate('/programwiseprojecttable', { state: { title:seriesName, seriesName,project_stage } });
//         }
//     };

//     return (
//         <React.Fragment>
//             <div className="container-fluid my-3">
//                 <Chart
//                     type="pie"
//                     className="custom-legend-chart"
//                     width={"100%"}
//                     height={450}
//                     series={Object.values(projectwisestage)}
//                     options={{
//                         chart: {
//                             toolbar: {
//                                 show: false,  
//                             },
//                             events: {
//                                 dataPointSelection: handleChartClick,
//                             },
//                         },
//                         noData: { text: "Empty Data" },
//                         labels: formattedLabels,
//                         colors: customColors,
//                         legend: {
//                             position: "right", 
//                             fontSize: '14px', 
//                             offsetY: -50,        
//                             itemMargin: {
//                               vertical: 5,      
//                             },
//                         },
//                     }}
//                 />
//             </div>
//         </React.Fragment>
//     );
// }

// export default ProjectStageWise;


// import React from "react";

// function ProjectStageWise({ startDate, endDate }) {
//   const grafanaBaseUrl = "http://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
//   const orgId = 1;
//   const timezone = "browser";
//   const panelId = 8;
//   const theme = "light";

//   // Convert start and end date to UNIX timestamps (milliseconds)
//   const from = startDate ? new Date(startDate).getTime() : Date.now() - 86400000; // Default: 24 hours ago
//   const to = endDate ? new Date(endDate).getTime() : Date.now(); // Default: now

//   // Construct Grafana iframe URL dynamically
//   const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

//   return (
//     <div className="container-fluid">
//       <iframe
//         src={grafanaUrl}
//         width="100%"
//         height="400"
//         frameBorder="0"
//         title="Program Wise Projects"
//         style={{ border: "none" }}
//       ></iframe>
//     </div>
//   );
// }

// export default ProjectStageWise;



import React from "react";


function ProgramWiseProjects({ startDate, endDate }) {
  const grafanaBaseUrl = "http://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
  const orgId = 1;
  const timezone = "browser";
  const panelId = 8;
  const theme = "light";
 


    const role = sessionStorage.getItem("designation_id");
    const userData = sessionStorage.getItem("user");
    const userId = JSON.parse(userData);
  
    // Access the 'id' field
    const user_id = userId.id;
    console.log("Extracted ID:", user_id);


  // Convert start and end date to UNIX timestamps (milliseconds)
  // const from = startDate ? startDate.format('YYYY-MM-DD') : null 
  // const to = endDate ? endDate.format('YYYY-MM-DD') : null 

  const from = startDate ? startDate.toISOString().split("T")[0] : null; // Format as YYYY-MM-DD
  const to = endDate ? endDate.toISOString().split("T")[0] : null;

const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}&var-from=${from}&var-to=${to}&var-role=${role}&var-user_id=${user_id}`;


 

  // Construct Grafana iframe URL dynamically
  // const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&var-from=${from}&var-to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ProgramWiseProjects;



