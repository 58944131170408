// import React, { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
// import { API_HEADER, project_stage_wise_url } from "../../../config";
// import axios from "axios";
// import { toast } from 'react-toastify';
// import { useNavigate } from "react-router-dom";

// function ProjectStageBar({ search, startDate, endDate }) {
//     const [projectwisestage, setProjectWiseStage] = useState({});
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const payload = { 
//                     "fy": "",
//                     'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
//                     'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
//                 };

//                 const response = await axios.post(`${project_stage_wise_url}`, payload, API_HEADER);
//                 setProjectWiseStage(response.data.data);
//             } catch (error) {
//                 toast.error(error);
//             }
//         };
//         fetchData();
//     }, [search, startDate, endDate]);

//     const customColors = [
//         "#3a9c97", "#ffb7c5", "#6b59cd", "#00cc99",
//         "#008081", "#edce7a", "#ee7036", "#178ab7",
//         "#a71666", "#d31638", "#4fb19d", "#823772"
//     ];

//     const formatLabel = (label) => {
//         return label.split('_')
//             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//             .join(' ');
//     };

//     const formattedLabels = Object.keys(projectwisestage).map(formatLabel);
//     const seriesData = Object.values(projectwisestage).map(value => value); // Convert data to series format

//     const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
//         const project_stage = w.globals.labels[dataPointIndex];
//         const title = w.globals.seriesNames[seriesIndex];
//         const seriesName = w.globals.seriesNames[seriesIndex];

//         if (project_stage) {
//             navigate('/programwiseprojecttable', { state: { title: project_stage, seriesName, project_stage } });
//         }
//     };

//     return (
//         <React.Fragment>
//             <div className="container-fluid my-3">
//                 <Chart
//                     type="bar" // Bar chart type
//                     className="custom-legend-chart"
//                     width={"100%"}
//                     height={450}
//                     series={[{
//                         name: 'Project Stages',
//                         data: seriesData, 
//                     }]}
//                     options={{
//                         chart: {
//                             toolbar: {
//                                 show: false,
//                             },
//                             events: {
//                                 dataPointSelection: handleChartClick,
//                             },
//                             margin: {
//                                 top: 20, // Add margin to the top of the chart
//                             },
//                         },
//                         plotOptions:{
//                             bar: {
//                                 horizontal: false,
//                                 columnWidth: '40%',
//                                 dataLabels: {
//                                   position: "top", // Display labels on top of the bars
//                                   offsetY: -10, // Slightly offset the labels above the bars
//                                 }
//                             }
                            
//                         },
//                         noData: { text: "Empty Data" },
//                         xaxis: {
//                             categories: formattedLabels, 
//                         },
//                         dataLabels:{
//                             enabled: true,
//                             position: "top", // Show the label on top of the bars
//                             style: {
//                               fontSize: "12px",
//                               fontWeight: "bold",
//                               colors: ["#304758"],
//                             },
//                             offsetY: -20, // Add padding to ensure it's outside the bar
//                             formatter: function (val) {
//                                 return val === 0 ? "" : val.toFixed(0); // Show empty string for 0
//                             },
//                         },
//                         colors: customColors,
//                         legend:{
//                             position: 'bottom',
//                         },
//                     }}
//                 />
//             </div>
//         </React.Fragment>
//     );
// }

// export default ProjectStageBar;


import React from "react";

function ProjectStageWise({ startDate, endDate }) {
  const grafanaBaseUrl = "http://grafana1.intileotech.xyz:3000/d-solo/aea99hs3003y8b/md-dashboard";
  const orgId = 1;
  const timezone = "browser";
  const panelId = 17;
  const theme = "light";

  // Convert start and end date to UNIX timestamps (milliseconds)
  const from = startDate ? new Date(startDate).getTime() : Date.now() - 86400000; // Default: 24 hours ago
  const to = endDate ? new Date(endDate).getTime() : Date.now(); // Default: now

  // Construct Grafana iframe URL dynamically
  const grafanaUrl = `${grafanaBaseUrl}?orgId=${orgId}&from=${from}&to=${to}&timezone=${timezone}&theme=${theme}&panelId=${panelId}`;

  return (
    <div className="container-fluid">
      <iframe
        src={grafanaUrl}
        width="100%"
        height="400"
        frameBorder="0"
        title="Program Wise Projects"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}

export default ProjectStageWise;
