import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, dvr_report_url, tr_action_url, get_project_team_info, team_change_doc_url, get_assesment_url } from "../../config";
import { get_project_details_url } from "../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../config";
import { DeleteTwoTone } from "@ant-design/icons";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import LoaderComponent from "../../utlis/LoderComponent";



const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const TeamChangeDocs = (props) => {
  const teamId = props.teamId
  const { id } = useParams();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size
  const designation_id = sessionStorage.getItem("designation_id");


  const [dvrStatus, setDvrStatus] = useState("");
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');

  const [fileCOISizeError, setFileCOISizeError] = useState(false);
  const [fileF24SizeError, setFileF24SizeError] = useState(false);
  const [fileF21SizeError, setFileF21SizeError] = useState(false);
  const [apiData, setAPIData] = useState('')


  const [coiname, setCoiName] = useState([]);
  const [coi, setCoi] = useState([]);
  const [newCoi, setNewCoi] = useState([]);

  const [f24Name, setF24Name] = useState(null);
  const [f24, setF24] = useState("");
  const [f21Name, setF21Name] = useState(null);
  const [f21, setF21] = useState("");

  const [f24File, setF24File] = useState("");
  const [f21File, setF21File] = useState("");
  // const [newCoi, setNewCoi] = useState("");


  const [teamChangeStatus, setTeamChangeStatus] = useState('')
  const [teamChangeId, SeteamChangeId] = useState('');

  const [loading, setLoading] = useState(false);

  const [tlRemarks, setTLRemarks] = useState('')
  const [member, setMember] = useState();

  const [isTL, setIsTL] = useState(false);
  const [isAT, setIsAT] = useState(false);

  const [teamLeader, setTeamLeader] = useState("");
  const [atlist, setAtList] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);


  const handleTlRemarksChange = (content) => {
    console.log(content)
    setTLRemarks(content)
  }

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_team_info}/${ids}?team_id=${teamId}`,
        API_HEADER
      );

      console.log("response,", response)

      setTeamChangeStatus(response.data?.record?.team_change_status)
      SeteamChangeId(response.data.teamsummary.id)
      setTLRemarks((response.data.record.team_change_status == 1 || response.data.record.team_change_status == 2) ? response.data.teamsummary.at_team_remarks == '' : response.data.teamsummary.at_team_remarks)
      setMember(response.data.teamsummary.team_change_status)
      setF21File(response.data.teamsummary.f24_doc)
      setF24File(response.data.teamsummary.f24_doc)
      // setNewCoi(response.data.teamsummary.coi_doc)
      // setCoiFile(response.data.teamsummary.coi_doc)


      // let url1 = `${BASE_DOCUMENT}/projects/${response.data.teamsummary.project_id}/${response.data.teamsummary.coi_doc}`;
      // setCoiName(url1);
      // setCoi(response.data.teamsummary.coi_doc);


      let url2 = `${BASE_DOCUMENT}/projects/${response.data.teamsummary.project_id}/${response.data.teamsummary.f24_doc}`;
      setF24Name(url2);
      console.log("url2", url2)
      setF24(response.data.teamsummary.f24_doc);

      let url3 = `${BASE_DOCUMENT}/projects/${response.data.teamsummary.project_id}/${response.data.teamsummary.f21_doc}`;
      setF21Name(url3);
      setF21(response.data.teamsummary.f21_doc);

      const accumulatedFiles = response.data.teamsummary.coi_doc.split(",").reduce((acc, item) => {
        const filename = `${BASE_DOCUMENT}/projects/${response.data.teamsummary.project_id}/${item}`;
        return {
          ...acc,
          [item]: { url: filename }
        };
      }, {});

      setCoiName(accumulatedFiles);
      setCoi(Object.keys(accumulatedFiles));


    } catch (error) { }
  };



  useEffect(() => {
    fetchData(id);
  }, [id]);



  const fetchDataProject = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );

      setAPIData(response.data.record)

      if (response && response.data && response.data.record) {
        const { record } = response.data;


        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record.program}`
        );
        setAtList(responseteamleader.data.data);
        setTeamLeader(record.team_leader);
        // setTLRemarks(record.at_team_remarks)


        const isTeamLeader = record.team.some((item) => item.role_id == 1);
        setIsTL(isTeamLeader);

        const isTeamMember = record.team.some((item) => item.role_id > 1);
        setIsAT(isTeamMember);

      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchDataProject(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };


  const handleFileCOIChange = (file) => {

    setNewCoi(prev => [...prev, ...Array.from(file)]);
    if (file[0].size <= dynamicMaxSize * 1024 * 1024) {
      setFileCOISizeError(false)
    }

    setFileCOISizeError(false)

    setCoi(prevFiles => [...prevFiles, ...Array.from(file)]);
  };

  const handleCOISizeError = (file) => {
    setFileCOISizeError(true)
  }

  const handleCOIDelete = (newCoi) => {
    setNewCoi(prevFiles => prevFiles.filter(file => file !== newCoi));
  }

  const handleFileF24Change = (file2) => {
    if (file2.size <= dynamicMaxSize * 1024 * 1024) {
      setFileF24SizeError(false);
    }
    setFileF24SizeError(false);
    setFile2(file2);
  };

  const handleF24SizeError = (file2) => {
    setFileF24SizeError(true);
  };

  const handleFileF21Change = (file3) => {
    if (file3.size <= dynamicMaxSize * 1024 * 1024) {
      setFileF21SizeError(false);
    }

    setFileF21SizeError(false);
    setFile3(file3);
  };
  const handleF21SizeError = (file3) => {
    setFileF21SizeError(true);
  };



  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);


    if ((tlRemarks == null || tlRemarks == "<p><br></p>" || tlRemarks == "") || (f24File == null || f24File == '') || (f21File == null || f21File == '')) {
      toast.error("Please upload the document and remarks.");
    } else {
      try {
        // setLoading(true);
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("team_change_status", member);
        formDataToSend.append("team_change_id", teamChangeId);
        formDataToSend.append("at_team_remarks", tlRemarks);
        newCoi.map(fileList =>
          formDataToSend.append("coi_doc[]", fileList)
        );
        // coiname.forEach(fileList => formDataToSend.append("coi_doc[]", fileList));
        formDataToSend.append("f24_doc", f24File);
        formDataToSend.append("f21_doc", f21File);

        const response = await axios.post(
          `${team_change_doc_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (
          fileCOISizeError ||
          fileF24SizeError ||
          fileF21SizeError
        ) {
          toast.error(`File size must below ${dynamicMaxSize}`);
        } else {
          toast.success("Document Submitted Successfully");
          setFile1(null);
          setF21File(null);
          setF24File(null);

          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        setIsSubmitting(false);
      }
    }
  };


  return (
    <>
      {

        ((!isTL && teamChangeStatus == 2 && designation_id == 8) || ((!isTL && teamChangeStatus == 1 && designation_id == 8))) ? (
          <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

            <form
              method="post"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Upload Document
                    </th>
                    {teamChangeStatus >= 2 ? (
                      <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                        Uploaded Document
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      COI Documents
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>

                    <td >

                      <FileUploader
                        handleChange={handleFileCOIChange}
                        name="coi_doc"
                        types={fileTypes}
                        multiple="true"
                        maxSize={dynamicMaxSize}
                        required={false}
                        onSizeError={handleCOISizeError}
                      />

                      {fileCOISizeError ? (
                        <span className="text-danger">
                          File size greater than {dynamicMaxSize} mb is not
                          allowed
                        </span>
                      ) : (
                        <span >
                          {newCoi.map((newCoi) => <div><span style={{ marginRight: '20px' }}>&nbsp;{<DeleteTwoTone twoToneColor="#eb2f96" onClick={() => handleCOIDelete(newCoi)} />}</span><span>{`File name: ${newCoi?.name}`}</span></div>)}
                        </span>
                      )}

                    </td>

                    <td>

                      {Object.keys(coiname).map((fileName) => (
                        <div key={fileName}>
                          <a target="_blank" href={coiname[fileName].url}>
                            {fileName}
                          </a>
                        </div>
                      ))}

                    </td>

                  </tr>
                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      F24 Document
                      <span style={{ color: "red" }}>*</span>
                    </td>


                    {/* <td className="p-3">
                  
                    <FileUploader
                      handleChange={handleFileF24Change}
                      name="f24_doc"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleF24SizeError}
                    />
                

                  {fileF24SizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} is not allowed
                    </span>
                  ) : (
                    <span>{file2 ? file2.name : ""}</span>
                  )}
                </td> */}

                    <FileUploaderComponent file={f24File} setFile={setF24File} />

                    <td>

                      <a href={f24Name} target="_blank">{f24}</a>

                    </td>
                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      F21 Document<span style={{ color: "red" }}>*</span>
                    </td>


                    {/* <td className="p-3">
                 
                    <FileUploader
                      handleChange={handleFileF21Change}
                      name="f21_doc"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleF21SizeError}
                    />
                  

                  {fileF21SizeError ? (
                    <span className="text-danger">
                      File size greater than {dynamicMaxSize} is not allowed
                    </span>
                  ) : (
                    <span>{file3 ? file3.name : ""}</span>
                  )}
                </td> */}

                    <FileUploaderComponent file={f21File} setFile={setF21File} />

                    <td>
                      <a href={f21Name} target="_blank">{f21}</a>
                    </td>
                  </tr>


                </tbody>
              </table>


              <div className="row">
                <div className="col-6 my-4 ">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    required
                    onChange={(e) => {
                      setMember(e.target.value);
                    }}
                  >
                    <option value=''>Select</option>

                    <option value={3}>Forward to TL</option>


                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    Team Member Remarks<span style={{ color: "red" }}>*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={tlRemarks}
                    name="remarks"
                    onChange={handleTlRemarksChange}
                    style={{
                      // width: "68vw",
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <div className="container mt-4 d-flex justify-content-end pr-0">
                <button
                  className="btn px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
              {loading && <div className="loader"></div>}
            </form>
          </>
        )
          :
          (
            <form
              method="post"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>
                    <td className="text-center lightgreen p-3">
                      COI Documents
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>


                    <td>
                      {Object.keys(coiname).map((fileName) => (
                        <div key={fileName}>
                          <a target="_blank" href={coiname[fileName].url}>
                            {fileName}
                          </a>
                        </div>
                      ))}

                    </td>

                  </tr>
                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      F24 Document
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>

                    <td>
                      <a href={f24Name} target="_blank">{f24}</a>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      F21 Document
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>

                    <td>
                      <a href={f21Name} target="_blank">{f21}</a>
                    </td>
                  </tr>


                </tbody>
              </table>

{tlRemarks &&
              <div className="row mt-4">
                <div>
                  <label className="form-label lightgreen fs-6 mb-3">
                    Team Member Remarks<span style={{ color: "red" }}>*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={tlRemarks}
                    name="remarks"
                    readOnly={true}
                    onChange={handleTlRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
}
            </form>
          )

      }
    </>
  );
};

export default TeamChangeDocs;
